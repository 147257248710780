import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";


// 登录
export const userLogin = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/agent/login`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 退出登录
export const logOut = (): Promise<AxiosResponse<object>> =>{
    return service({
        method:'GET',
        url:`${api}/agent-api/api/agent/logout`,
        headers:{
            "authorization":'Bearer '+ localStorage.token
        }
        
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}