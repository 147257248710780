
import { defineComponent ,reactive , ref, nextTick, onMounted} from 'vue';
import { Form } from 'ant-design-vue';
import { useRouter } from "vue-router";
import { userLogin } from '@/api/login/index';
import { message } from 'ant-design-vue';
import store from '@/store'
const useForm = Form.useForm;
export default defineComponent({
  name: 'login',
  setup(){
    const router = useRouter();
    // 15123456788 zhiyadaili123
    const modelRef = reactive({
      mobile: '15123456788',
      password:'zhiyadaili123',
      checked:false
    });
    const formRef = ref();
    const rules:any = {
     mobile: [
        { required: true, message: '请输入您的账号', trigger: 'blur' },
      ],
      password: [
        { required: true, message: '请输入账号密码', trigger: 'blur' },
      ],
   }
    onMounted(()=>{
      (modelRef as any).mobile = localStorage.getItem('mobile');
      (modelRef as any).password = localStorage.getItem('password');
      if(modelRef.mobile && modelRef.password){
        modelRef.checked = true;
      }
      
    })
    const onSubmit = () => {
      nextTick(()=>{
        formRef.value.validate().then(() => {
          // 记住密码
          if(modelRef.checked){
            localStorage.setItem('mobile',modelRef.mobile)
            localStorage.setItem('password',modelRef.password)
          }else{
            localStorage.removeItem('mobile')
            localStorage.removeItem('password')
          }
          userLogin({mobile:modelRef.mobile,password:modelRef.password}).then((res:any)=>{
            if(res.code == 200){
              message.success('登录成功')
              localStorage.setItem('token',res.data.token)
              localStorage.setItem('userInfo',JSON.stringify(res.data.userInfo))
              localStorage.setItem('avatar',res.data.userInfo.avatar)
              store.commit('setUserInfo',res.data.userInfo)
              // router.push({name:'sale'})
              location.href = '/index';
            }else{
              message.error(res.msg)
            }
          })
        }).catch((Error:any) => {
          console.log('error', Error);
        });
      })
    };


    const login = ()=>{
      console.log('登录');
      onSubmit()
    }
    return {
      login,
       labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      rules,
      modelRef,
      formRef,
      onSubmit,
    }
  }
});
